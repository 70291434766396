import { createStore } from "vuex";
import router from "../router";

export default createStore({
  state: {
    isSignedIn: false,
  },
  mutations: {
    signIn(state) {
      console.log("mutate.login");
      state.isSignedIn = true;
    },
    signOut(state) {
      state.isSignedIn = false;
    },
  },
  actions: {
    signIn(context) {
      context.commit("signIn");
      router.push({ path: "/" });
    },

    signOut(context) {
      console.log("action.signout");
      context.commit("signOut");
      router.push({ path: "/login" });
    },
  },
  modules: {},
});
