<template>
  <router-link to="/">Home</router-link>
  <router-link to="/about">About</router-link>
  <router-link to="/applications" v-if="this.$store.state.isSignedIn"
    >Applications</router-link
  >
  <router-link to="/settings" v-if="this.$store.state.isSignedIn"
    >Settings</router-link
  >
  <router-link to="/login" v-if="!this.$store.state.isSignedIn"
    >Sign In</router-link
  >
  <a v-if="this.$store.state.isSignedIn" href="#" @click.prevent="SignOut"
    >Sign Out</a
  >
</template>

<script>
export default {
  name: "Sidebar",
};
</script>

<style></style>
