<template>
  <div id="body-wrapper">
    <div id="body-header">
      <Header />
    </div>
    <div id="body-sidebar">
      <Sidebar />
    </div>
    <div id="body-content">
      <router-view />
    </div>
    <div id="body-footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/views/components/Header.vue";
import Footer from "@/views/components/Footer.vue";
import Sidebar from "@/views/components/Sidebar.vue";
export default {
  data() {
    return {};
  },

  components: { Header, Footer, Sidebar },
  created() {
    this.$router.push({ name: "Login" });
  },

  methods: {},
  watch: {},
  computed: {
    isSignedIn() {
      return this.$store.state.isSignedIn;
    },
  },
};
</script>
