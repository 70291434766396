export const isValidTel = (value) => {
  let pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return pattern.test(value);
};

export const isComplexPassword = (value) => {
  //let pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  let minLength = value.length >= 8;
  let hasUpperCase = /[A-Z]/.test(value);
  let hasLowerCase = /[a-z]/.test(value);
  let hasNumbers = /\d/.test(value);
  let hasNonalphas = /\W/.test(value);
  return (
    minLength && hasUpperCase && hasLowerCase && hasNumbers && hasNonalphas
  );
};
