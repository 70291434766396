<template>
  <div class="card">
    <div class="card-body">
      <h3 class="card-title">Forgot Password</h3>
      <form @submit.prevent="resetPassword">
        <div class="form-floating mb-3" :class="{ error: v$.email.$error }">
          <input
            type="text"
            class="form-control"
            placeholder="Email"
            v-model="email"
          />
          <label for="email">Email</label>
          <span v-if="v$.email.$error" class="help-text">{{
            v$.email.$errors[0].$message
          }}</span>
        </div>
        <button class="btn btn-primary mb-3">Reset my password</button>
      </form>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
  data() {
    return {
      email: "",
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
      validationConfig: {
        $autoDirty: true,
      },
    };
  },
  validations() {
    return {
      email: { required, email },
    };
  },
  methods: {
    resetPassword() {
      this.v$.$touch();
    },
  },
};
</script>
