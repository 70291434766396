<template>
  <div id="login">
    <SignIn v-if="SignIn" />
    <Register v-if="Register" />
    <ForgotPassword v-if="ForgotPassword" />
    <div class="card">
      <div class="card-body">
        <a v-if="!SignIn" @click="page('SignIn')" class="link-primary mx-3"
          >SignIn</a
        >
        <a v-if="!Register" @click="page('Register')" class="link-primary mx-3"
          >Register</a
        >
        <a
          v-if="!ForgotPassword"
          @click="page('ForgotPassword')"
          class="link-primary mx-3"
        >
          Forgot Password
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import SignIn from "@/views/Login/SignIn.vue";
import Register from "@/views/Login/Register.vue";
import ForgotPassword from "@/views/Login/ForgotPassword.vue";

export default {
  data() {
    return {
      SignIn: true,
      Register: false,
      ForgotPassword: false,
    };
  },
  components: {
    SignIn,
    Register,
    ForgotPassword,
  },
  methods: {
    page(opt) {
      ["Register", "SignIn", "ForgotPassword"].forEach((view) => {
        this[view] = view === opt ? true : false;
      });
    },
  },
};
</script>

<style>
#login {
  width: 100%;
  max-width: 500px;
  height: auto;
  margin: 30px auto;
  text-align: center;
}
</style>
