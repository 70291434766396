import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import About from "../views/About.vue";
import Applications from "../views/Applications.vue";
import Settings from "../views/Settings.vue";
import Login from "../views/Login.vue";
import Privacy from "../views/Privacy.vue";
import ContactUs from "../views/ContactUs.vue";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard",
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      title: "About Us",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Signin",
    },
  },
  {
    path: "/applications",
    name: "Applications",
    component: Applications,
    meta: {
      title: "Applications",
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      title: "Settings",
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    meta: {
      title: "Privacy",
    },
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
    meta: {
      title: "Contact Us",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let title = document.title.split(" - ")[0];
  document.title = title + " - " + to.meta.title;
  next();
});

export default router;
