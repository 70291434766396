<template>
  <router-link to="/login" v-if="!this.$store.state.isSignedIn"
    >Sign In</router-link
  >
  <a v-if="this.$store.state.isSignedIn" href="#" @click.prevent="SignOut"
    >Sign Out</a
  >
</template>

<script>
export default {
  name: "Header",
  methods: {
    SignOut() {
      this.$store.dispatch("signOut");
    },
  },
};
</script>

<style></style>
