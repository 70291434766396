<template>
  <div class="card" id="signin">
    <div class="card-body">
      <h3 class="card-title">Register Account</h3>

      <form @submit.prevent="register" novalidate>
        <p>
          I am a

          <label class="mx-3"
            ><input
              type="radio"
              name="type"
              v-model="user.type"
              value="company"
            />
            Company</label
          >
          <label
            ><input
              type="radio"
              name="type"
              v-model="user.type"
              value="individual"
            />
            Individual</label
          >
        </p>

        <div
          class="form-floating mb-3"
          v-if="user.type === 'company'"
          :class="{ error: v$.user.company.$error }"
        >
          <input
            type="text"
            class="form-control"
            v-model="user.company"
            id="company"
            placeholder="company"
          />
          <label for="company">Company</label>
          <span v-if="v$.user.company.$error" class="help-text">{{
            v$.user.company.$errors[0].$message
          }}</span>
        </div>

        <div
          class="form-floating mb-3"
          :class="{ error: v$.user.firstName.$error }"
        >
          <input
            type="text"
            class="form-control"
            v-model="user.firstName"
            id="firstName"
            placeholder="First Name"
          />
          <label for="firstName">First Name</label>
          <span v-if="v$.user.firstName.$error" class="help-text">{{
            v$.user.firstName.$errors[0].$message
          }}</span>
        </div>

        <div
          class="form-floating mb-3"
          :class="{ error: v$.user.lastName.$error }"
        >
          <input
            type="text"
            class="form-control"
            v-model="user.lastName"
            id="lastName"
            placeholder="Last Name"
          />
          <label for="lastName">Last Name</label>
          <span v-if="v$.user.lastName.$error" class="help-text">{{
            v$.user.lastName.$errors[0].$message
          }}</span>
        </div>

        <div
          class="form-floating mb-3"
          :class="{ error: v$.user.email.$error }"
        >
          <input
            type="email"
            class="form-control"
            v-model="user.email"
            id="email"
            placeholder="Email"
          />
          <label for="email">Email</label>
          <span v-if="v$.user.email.$error" class="help-text">{{
            v$.user.email.$errors[0].$message
          }}</span>
        </div>

        <div class="form-floating mb-3" :class="{ error: v$.user.tel.$error }">
          <input
            type="tel"
            class="form-control"
            v-model="user.tel"
            id="tel"
            placeholder="Contact Number"
          />
          <label for="tel">Tel</label>
          <span v-if="v$.user.tel.$error" class="help-text">{{
            v$.user.tel.$errors[0].$message
          }}</span>
        </div>

        <div
          class="form-floating mb-3"
          :class="{ error: v$.user.password.$error }"
        >
          <input
            type="text"
            class="form-control"
            v-model="user.password"
            placeholder="Password"
            id="password"
          />
          <label for="password">Password</label>
          <span v-if="v$.user.password.$error" class="help-text">{{
            v$.user.password.$errors[0].$message
          }}</span>
        </div>

        <button class="btn btn-primary">Register</button>
      </form>
    </div>
  </div>
</template>

<script>
/* form validations */
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, maxLength } from "@vuelidate/validators";
import { isValidTel, isComplexPassword } from "@/assets/validationRules.js";

export default {
  data() {
    return {
      user: {
        type: "company",
        company: "",
        firstName: "",
        lastName: "",
        email: "",
        tel: "",
        password: "",
      },
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
      validationConfig: {
        $autoDirty: true,
      },
    };
  },
  validations() {
    return {
      user: {
        company: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(32),
        },
        firstName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(32),
        },
        lastName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(32),
        },
        email: { required, email },
        password: {
          required,
          maxLength: maxLength(32),
          complexPassword: {
            $validator: isComplexPassword,
            $message:
              "Password must be a minimum of 8 charecters and contain upper case, lower case, numbers and special charecters!",
          },
        },
        tel: {
          required,
          validTel: {
            $validator: isValidTel,
            $message: "Enter a valid telephone number!",
          },
        },
      },
    };
  },
  methods: {
    register() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        console.log("Form is not valid");
      } else {
        console.log("Form is Valid");
      }
    },
  },
};
</script>
