<template>
  <h1>Applications</h1>
</template>

<script>
export default {};
</script>

<style>
body {
  font-size: 16px;
}
</style>
