<template>
  <h1>{{ msg }}</h1>
  <h3>Dashboard __ Hello Pets</h3>
</template>

<script>
export default {
  name: "Dashboard",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
