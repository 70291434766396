<template>
  <h1>Settings</h1>
</template>

<script>
export default {
  data() {
    return {
      x: null,
    };
  },
  components: {},
};
</script>

<style>
body {
  font-size: 16px;
}
</style>
