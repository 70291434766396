<template>
  <router-link to="/privacy">Privacy</router-link> |
  <router-link to="/contact-us">Contact Us</router-link> |
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  border-top: 1px solid grey;
  margin: 5px 0;
  padding: 5px 0;
  text-align: "center";
}
</style>
