<template>
  <div class="card">
    <div class="card-body">
      <h1 class="card-title">SignIn</h1>
      <form class="login" @submit.prevent="login" novalidate>
        <div class="form-floating mb-3" :class="{ error: v$.email.$error }">
          <input
            required
            v-model="email"
            type="text"
            placeholder="Email"
            class="form-control"
            autocomplete="email"
          />
          <label>Email</label>
          <span v-if="v$.email.$error" class="help-text">{{
            v$.email.$errors[0].$message
          }}</span>
        </div>
        <div class="form-floating mb-3" :class="{ error: v$.password.$error }">
          <input
            required
            v-model="password"
            type="password"
            placeholder="Password"
            class="form-control"
            autocomplete="current-password"
          />
          <label>Password</label>
          <span v-if="v$.password.$error" class="help-text">{{
            v$.password.$errors[0].$message
          }}</span>
        </div>
        <div v-if="signInError">
          <div class="alert alert-danger">Invalid User name / Password....</div>
        </div>

        <button type="submit" class="btn btn-primary">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
/* form validations */
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";

export default {
  data() {
    return {
      email: "",
      password: "",
      signInError: false,
    };
  },

  setup() {
    return {
      v$: useVuelidate(),
      validationConfig: {
        $autoDirty: true,
      },
    };
  },

  methods: {
    login() {
      console.log("login()");
      this.v$.$touch();
      if (this.v$.invalid) {
        alert("Invalid Data");
        this.signInError = true;
      } else {
        this.$store.dispatch("signIn");
      }
    },
  },

  validations() {
    return {
      email: { required, email },
      password: { required, minLength: minLength(8) },
    };
  },
};
</script>
